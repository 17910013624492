<template>
    <div class="cg_main">
        <div style="width: 100%;height: 5px;background: #EFEFEF; "></div>
        <div class="cg_main_son">
            <div style="text-align: right;margin-bottom: 20px;margin-top: 40px;">

                <el-breadcrumb separator="/">
                    <el-breadcrumb-item><router-link :to="{path:'/configurationList'}">我的项目</router-link></el-breadcrumb-item>
                    <el-breadcrumb-item v-if="this.$route.query.name">{{this.$route.query.name}}</el-breadcrumb-item>
                    <el-breadcrumb-item v-else>配置单详情</el-breadcrumb-item>
                </el-breadcrumb>
              <div style="float: left;     width: 100%;
    text-align: left;
    padding-top: 20px;" >
              <el-tag >
               参数说明：{{ this.describe}}
              </el-tag>
              </div>
                <div style="color: red;float: left;padding-top: 20px;" v-show="redTitle">友情提示：记得检查两列通道长度是否相等喔！</div>
                <el-button v-if="this.$route.query.isR=='i'" @click="goBack()" type="primary" >返回</el-button>
                <el-button @click="searchRow()" type="primary" style="margin-top: 10px;">搜物料</el-button>
                <el-button @click="addRow()" type="primary" >添加自定义产品</el-button>
               <!-- <el-input style="width: 240px;margin-right: 10px" placeholder="请输入内容" v-model="input3" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>-->
                <!-- <el-button type="primary" plain>检查</el-button>-->
                <!--<el-button type="primary" plain @click="openDia">添加产品</el-button>-->
                <!--<el-button type="primary" plain @click="openDia">保存</el-button>-->
               <!-- <div style="text-align: right;margin-bottom: 30px;" @click="openDia">
                    <el-button type="primary" >保存</el-button>
                </div>-->
            </div>
            <el-collapse v-model="activeNames2" @change="handleChange">
                <el-collapse-item title="柜体布局图" name="1">
                    <img :src="this.img"  style="max-width: 100%;max-height: 330px;" />
                    <div style="clear: both"></div>
                    <div v-for="item in imgList" class="imgLstyle" :key="item.path">
                        <div class="imgLstyleT"><img  :src="item.path"  /></div>
                        <div class="imgLstyleD">{{item.describe}}</div>
                    </div>


                </el-collapse-item>

            </el-collapse>
            <div style="margin-bottom: 40px;">
                <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item v-for="(item,index) in tableAllData" :title="item.title" :name="index" :key="item.title">
                    <!-- <el-collapse-item v-for="(item,index) in tableAllData" :title="item.title+' 【总价:'+item.belongPrice+'元】'" :name="index" :key="item.title"> -->
                        <el-popconfirm   title="当前物料配置存在问题,是否采用推荐方案？" @confirm="goChange(item.title)">
                            <el-button v-show="item.warning==true" type="text" style="float:right;padding-top: 20px;cursor: pointer;" slot="reference">
                                <div  ><img src="../image/dange.png" style="cursor: pointer;"> </div>
                            </el-button>

                        </el-popconfirm>

                        <el-table
                                :data="item.data"
                                style="width: 100%;padding: 30px;padding-top: 0px;">
                            <el-table-column
                                    prop="code"
                                    label="订购代码" show-overflow-tooltip
                                    width="150">
                            </el-table-column>
                            <el-table-column
                                    prop="saleName"
                                    label="产品名称" show-overflow-tooltip
                                    width="130">
                            </el-table-column>
                            <el-table-column
                                    prop="brand"
                                    label="品牌" show-overflow-tooltip
                                    width="120">
                            </el-table-column>
                            <el-table-column
                                    prop="modelNumber" show-overflow-tooltip
                                    label="型号">
                            </el-table-column>
                            <el-table-column
                                    prop="saleDescription"
                                    label="规格参数"
                                    width="340"
                                    show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column
                                    prop="saleUnit" show-overflow-tooltip
                                    label="销售单位">
                            </el-table-column>
                            <!-- <el-table-column
                                    v-if="priceShow"

                                    prop="facePrice" show-overflow-tooltip
                                    label="单价">
                            </el-table-column>
                            <el-table-column

                                    prop="discount" show-overflow-tooltip
                                    label="折扣率">
                                <template scope="scope">
                                <template slot-scope="scope">
                                    <span>{{scope.row.discount}}%</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                    v-if="disCountShow"

                                    prop="discount" show-overflow-tooltip
                                    label="折后价">
                                <template scope="scope">
                                <template slot-scope="scope">
                                    <span>{{discountP(scope.row)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    v-if="false"
                                    prop="sign" show-overflow-tooltip
                                    label="单价">
                            </el-table-column> -->
                            <el-table-column width="90"
                                    prop="counts"
                                    label="数量">
                                <template slot-scope="scope">

                                    <el-input style="width: 80px"   type="number" size="small" v-model="scope.row.counts" :min="0" placeholder="请输入内容"></el-input>

                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="90"
                                    prop="sign" show-overflow-tooltip
                                    label="备注">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.sign=='电缆1红' || scope.row.sign=='电缆1蓝' || scope.row.sign=='电缆1黑'">
                                        UPS到总电池开关箱电缆
                                    </span>
                                    <span v-else-if="scope.row.sign=='电缆2红' || scope.row.sign=='电缆2蓝' || scope.row.sign=='电缆2黑'">
                                        分电池开关箱到总电池开关箱
                                    </span>
                                    <span v-else-if="scope.row.sign=='电缆UPS' ">
                                        UPS到配电柜电缆
                                    </span>
                                    <span v-else-if="scope.row.sign=='电缆PDU' ">
                                        配电柜到PDU电缆
                                    </span>
                                    <span v-else-if="scope.row.sign=='电缆SW' ">
                                        空调外机电缆
                                    </span>
                                    <span v-else-if="scope.row.sign=='电缆SN' ">
                                        空调内机电缆
                                    </span>
                                    <span v-else-if="scope.row.sign=='空调辅材' || scope.row.sign=='制冷剂' || scope.row.sign=='冷冻油'">
                                        根据现场实际长度调整
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    label="操作" width="150">
                                <template slot-scope="scope">

                                    <el-button  @click="editNum(scope.row)" type="text" style="color: #0A8EFF;margin-right: 5px;" slot="reference">保存</el-button>
                                    <el-popconfirm title="确定删除？" @confirm="delList(scope.row.id,scope.$index,item.data)">
                                        <el-button type="text" style="color: #0A8EFF" slot="reference">删除</el-button>
                                    </el-popconfirm>

                                </template>
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                    <!--<el-collapse-item title="配电系统" name="2">
                        <el-table
                                :data="tableData2"
                                style="width: 100%;padding: 30px;">
                            <el-table-column
                                    prop="saleName"
                                    label="产品名称" show-overflow-tooltip
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="brand"
                                    label="品牌" show-overflow-tooltip
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="modelNumber" show-overflow-tooltip
                                    label="型号">
                            </el-table-column>
                            <el-table-column
                                    prop="saleDescription"
                                    label="规格参数"  width="380"
                                    show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column
                                    prop="saleUnit" show-overflow-tooltip
                                    label="销售单位">
                            </el-table-column>
                            <el-table-column
                                    prop="counts" show-overflow-tooltip
                                    label="数量">
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                    <el-collapse-item title="制冷系统" name="3">
                        <el-table
                                :data="tableData3"
                                style="width: 100%;padding: 30px;">
                            <el-table-column
                                    prop="saleName"
                                    label="产品名称" show-overflow-tooltip
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="brand"
                                    label="品牌" show-overflow-tooltip
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="modelNumber" show-overflow-tooltip
                                    label="型号">
                            </el-table-column>
                            <el-table-column
                                    prop="saleDescription"
                                    label="规格参数"  width="380"
                                    show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column
                                    prop="saleUnit" show-overflow-tooltip
                                    label="销售单位">
                            </el-table-column>
                            <el-table-column
                                    prop="counts" show-overflow-tooltip
                                    label="数量">
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                    <el-collapse-item title="动环系统" name="4">
                        <el-table
                                :data="tableData4"
                                style="width: 100%;padding: 30px;">
                            <el-table-column
                                    prop="saleName"
                                    label="产品名称" show-overflow-tooltip
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="brand"
                                    label="品牌" show-overflow-tooltip
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="modelNumber" show-overflow-tooltip
                                    label="型号">
                            </el-table-column>
                            <el-table-column
                                    prop="saleDescription"
                                    label="规格参数"  width="380"
                                    show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column
                                    prop="saleUnit" show-overflow-tooltip
                                    label="销售单位">
                            </el-table-column>
                            <el-table-column
                                    prop="counts" show-overflow-tooltip
                                    label="数量">
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>-->
                </el-collapse>
            </div >
            <!-- <div style="float: left">
                工程报价:<span style="color: red">{{projPrice}}</span><br><br>
                产品总价:<span style="color: red">{{listPrice}}</span>
            </div> -->
            <div v-show="isEdit==false"  style="text-align: right;margin-bottom: 30px;" @click="openDia">
                <el-button type="primary" >保存</el-button>
            </div>
            <div v-show="isEdit==true"   style="text-align: right;margin-bottom: 30px;" @click="openDia">
                <el-button type="primary" >完成</el-button>
            </div>

            <el-dialog title="配置单名称填写" :visible.sync="dialogVisible" width="370px">
                <el-form  label-width="100px">
                    <el-form-item v-show="addShow"><el-button @click="openAddShow">新建项目</el-button></el-form-item>
                    <el-form-item v-show="!addShow"><el-button @click="closeAddShow">返回</el-button></el-form-item>
                    <el-form-item label="已有项目" v-show="addShow">
                        <el-select v-model="xmList" placeholder="请选择项目" >
                            <el-option
                                    v-for="item in listChange"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目名称" v-show="!addShow">
                        <el-input v-model="input1" placeholder="请输入项目名称"></el-input>
                    </el-form-item>
                    <el-form-item label="配置单名称" >
                        <el-input v-model="input" placeholder="请输入配置单名称"></el-input>
                    </el-form-item>
                    <el-form-item label="套数" >
                        <el-input v-model="inputTs" placeholder="请输入套数"></el-input>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="savePLFin" :loading="btnLoading" v-show="addShow">确 定</el-button>
                <el-button type="primary" @click="saveAddPLFin" :loading="btnLoading" v-show="!addShow">确 定</el-button>
            </span>
            </el-dialog>

            <el-dialog title="新增配置" :visible.sync="dialogVisible2" width="370px">
                <el-form  label-width="100px">
                    <el-form-item label="产品类别">
                        <el-select v-model="formdata.belong" placeholder="请选择项目">
                            <el-option
                                    v-for="item in tableAllData"
                                    :label="item.title"
                                    :value="item.title"
                                    :key="item.title">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="产品名称" >
                        <el-input v-model="formdata.saleName" placeholder="请输入产品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="品牌" >
                        <el-input v-model="formdata.brand" placeholder="请输入品牌"></el-input>
                    </el-form-item>
                    <el-form-item label="型号" >
                        <el-input v-model="formdata.modelNumber" placeholder="请输入型号"></el-input>
                    </el-form-item>
                    <el-form-item label="规格参数" >
                        <el-input v-model="formdata.saleDescription" placeholder="请输入规格参数"></el-input>
                    </el-form-item>
                    <el-form-item label="销售单位" >

                        <el-select v-model="formdata.saleUnit" placeholder="请输入销售单位">
                            <el-option label="PCS" value="PCS"></el-option>
                            <el-option label="SET" value="SET"></el-option>
                            <el-option label="m" value="m"></el-option>
                            <el-option label="kg" value="kg"></el-option>
                        </el-select>
                    </el-form-item>
                   <!-- <el-form-item label="单价" >
                        <el-input v-model="formdata.facePrice" placeholder="请输入单价"></el-input>
                    </el-form-item>-->
                    <el-form-item label="数量" >
                        <el-input v-model="formdata.counts" placeholder="数量"></el-input>
                    </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="addFin" :loading="btnLoading" >确 定</el-button>
            </span>
            </el-dialog>

            <el-dialog title="提示信息" :visible.sync="dialogVisible3" width="370px">
                <el-table
                        :data="eroList"
                        style="width: 100%;">
                    <el-table-column
                            prop="saleName"
                            label="" show-overflow-tooltip
                           >
                    </el-table-column>
                    <el-table-column
                            prop="address"
                            label="操作" width="50">
                        <template slot-scope="scope">
                            <el-popconfirm title="确定修改？" @confirm="goRight(scope.row.id,scope.$index)">
                                <el-button type="text" style="color: #0A8EFF" slot="reference">修改</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible3 = false">取 消</el-button>
            </span>
            </el-dialog>

            <el-dialog title="物料情况" :visible.sync="dialogVisible4" width="800px" style="overflow-y: auto">
                <el-row :gutter="20" style="margin-bottom: 20px;">
                    <el-col :span="16">
                        <el-input placeholder="可搜索产品名称/型号/描述" v-model="searchInput" class="input-with-select" @keyup.enter.native="getSearch()">
                            <el-button @click="getSearch()" slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <el-button @click="addSearch()" type="primary" >新增</el-button>
                    </el-col>
                </el-row>


                <el-table
                        ref="multipleTable"
                        :data="wlList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        @selection-change="handleSelectionChange">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            prop="code"
                            label="订购代码" show-overflow-tooltip
                            width="150">
                    </el-table-column>
                    <el-table-column
                            prop="saleName"
                            label="产品名称" show-overflow-tooltip
                            width="130">
                    </el-table-column>
                    <el-table-column
                            width="150"
                            prop="modelNumber" show-overflow-tooltip
                            label="型号">
                    </el-table-column>
                    <el-table-column
                            prop="facePrice" show-overflow-tooltip
                            label="单价">
                    </el-table-column>
                    <el-table-column
                            width="150"
                            prop="saleDescription" show-overflow-tooltip
                            label="销售描述">
                    </el-table-column>

                </el-table>

                <span slot="footer" class="dialog-footer">

            </span>
            </el-dialog>
        </div>

    </div>
</template>

<script>
    import {
        getConfigList,
        getConfigFin,
        getConfigFinDetail,
        projectList,
        getConfigPLFin,
        tempEdit,
        tempDel,
        tempList,
        customAdd,
        aloChange,
        aloDelTrue,
        aloEditTrue,
        aloChangeTrue,
        addFinalTrue,
        projectAddNew,
        customSearch,
        customAddBatch,
        customAddBatchFinal,
    } from "@/Api/finance/home";
    import { encrypt,decrypt } from "@/Util/auth";
    export default {
        name: "index",
        data() {
            return {
                type:'',
                img:'',
                imgList:'',
                priceShow:true,
                redTitle:true,
                orderCodes:[],
                wlList:[],
                addShow:true,
                eroList:[
                    {
                        saleName:'123221321',
                    },
                    {
                        saleName:'123221321',
                    },
                    {
                        saleName:'123221321',
                    },
                ],
                formdata:{
                    tempListId:this.$route.query.id,
                    "saleUnit": "", //销售单位 PCS/SET/KG/M
                    "saleDescription": "", //销售描述
                    "saleName": "", //品名
                    "brand": "", //品牌
                    "modelNumber": "", //型号
                    "counts": "", //数量
                    belong:'',
                   /* facePrice:'',*/
                },
                inputTs:'1',
                projPrice:'',
                listPrice:'',
                xmList:'',
                listChange: [],
                btnLoading: false,
                activeNames: [],
                activeNames2: ['1'],
                dialogVisible:false,
                dialogVisible2:false,
                dialogVisible3:false,
                dialogVisible4:false,
                /*tableData1:[],
                tableData2:[],
                tableData3:[],
                tableData4:[],*/
                tableAllData:[],
                describe:'',

                input:'',
                input1:'',
                isEdit:'',
                searchInput:'',
            }
        },
        methods: {
            discountP(row){
              let result=row.facePrice*row.discount/100
              return isNaN(result)?'':result
            },
            addSearch(){

                let obj = {
                    orderCodes:this.orderCodes,
                    listId:this.$route.query.id
                };
                let ss= encrypt(JSON.stringify(obj))
                if(this.$route.query.detial=='1'){
                    customAddBatchFinal(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            alert(dd.msg);
                            this.dialogVisible4=false
                            this.getConfigDetialList()
                        }else {
                            alert(dd.msg);
                        }

                    })
                }else {
                    customAddBatch(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            alert(dd.msg);
                            this.dialogVisible4=false
                            this.gettempList()
                        }else {
                            alert(dd.msg);
                        }

                    })
                }

            },
            getSearch(){

                let obj = {
                    "searchData":this.searchInput,
                    "type":this.type
                };
                let ss= encrypt(JSON.stringify(obj))
                customSearch(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.wlList=dd.data
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            handleSelectionChange(val) {
                this.orderCodes=[]
                val.map(item=>{
                    this.orderCodes.push(item.orderCode)
                })
            },
            searchRow(){
                this.orderCodes=[]
                this.dialogVisible4=true
            },
            openAddShow(){
                this.addShow=false
            },
            closeAddShow(){
                this.addShow=true
            },
            goRight(){
              alert('s')
            },
            goChange(title){

                if(this.isEdit==false){
                    let obj = {
                        "belong":title,
                        "tempListId": this.$route.query.id
                    };
                    let ss= encrypt(JSON.stringify(obj))
                    aloChange(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            this.$message.success(`替换成功`);
                            this.gettempList()
                        }else {
                            alert(dd.msg);
                        }

                    })
                }else {
                    let obj = {
                        "belong":title,
                        "listId": this.$route.query.id
                    };
                    let ss= encrypt(JSON.stringify(obj))
                    aloChangeTrue(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            this.$message.success(`替换成功`);
                            this.getConfigDetialList()
                        }else {
                            alert(dd.msg);
                        }

                    })
                }

            },
            openEro(){
                this.dialogVisible3=true
            },
            gettempList(){

                let parmars={
                    id:this.$route.query.id
                }
                let parmarsCode= encrypt(JSON.stringify(parmars))
                tempList(parmarsCode).then(res => {

                    let resCoDE= JSON.parse(decrypt(res))
                    console.log(resCoDE,'resCoDE')
                    if (resCoDE.code == 200) {
                        this.type=resCoDE.data.type
                        if(resCoDE.data.data.length>0){
                            /*this.tableAllData= resCoDE.data*/
                            this.img=resCoDE.data.layoutImg

                            this.imgList=resCoDE.data.legendList
                            this.tableAllData= resCoDE.data.data.map(obj=>{
                                obj.brand=resCoDE.data.brand
                                return obj
                           })
                          this.describe=resCoDE.data.describe
                            /*if(this.tableAllData.length>0){
                                this.tableAllData.map((item,index)=>{
                                    if(item.data.length>0){
                                        item.data.map((item1,index1)=>{
                                            item1.show=false
                                        })

                                    }
                                })
                            }*/
                            console.log(this.tableAllData)
                            this.projPrice= resCoDE.data.params.projPrice
                            this.listPrice= resCoDE.data.params.listPrice
                            resCoDE.data.data.map((item,index)=>{
                                this.activeNames.push(index)
                            })
                            /* if(item.data.saleName !=null){
                                /!* if(item.belong=='一'){
                                     this.tableData1.push(item)
                                 }else if(item.belong=='二'){
                                     this.tableData2.push(item)
                                 }else if(item.belong=='三'){
                                     this.tableData3.push(item)
                                 }else if(item.belong=='四'){
                                     this.tableData4.push(item)
                                 }*!/
                             }*/
                            /*  this.activeNames.push(index)
                              this.tableAllData=resCoDE.data*/
                        }
                    }else {
                        alert(resCoDE.msg);
                    }

                })
            },
            delList(id,index,data){
                let obj = {
                    ids: [id],
                };
                let ss= encrypt(JSON.stringify(obj))
                if(this.isEdit==false){
                    tempDel(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            data.splice(index, 1)
                        }else {
                            alert(dd.msg);
                        }

                    })
                }else {
                    aloDelTrue(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            data.splice(index, 1)
                        }else {
                            alert(dd.msg);
                        }

                    })
                }

            },
            editNum(row){

                let obj = {
                    id: row.id,
                    counts: row.counts,
                    sign:row.sign
                };

                let ss= encrypt(JSON.stringify(obj))
                if(this.isEdit==false){
                    tempEdit(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            this.$message.success(`保存成功`);
                            this.gettempList()
                        }else {
                            alert(dd.msg);
                        }

                    })
                }else {
                    aloEditTrue(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            this.$message.success(`保存成功`);
                            this.getConfigDetialList()
                        }else {
                            alert(dd.msg);
                        }

                    })
                }

            },
            goBack(){
              if(this.$route.query.indextype=='1') {
                this.$router.push({name: 'requirement', query: {isR: 'i'}})
              }
              else if(this.$route.query.indextype=='2')
              {
                this.$router.push({name: 'requirementhotandcold', query: {isR: 'i'}})
              } else if(this.$route.query.indextype=='3')
              {
                  this.$router.push({name: 'mdCustom', query: {isR: 'i'}})
              }
            },
            addRow(){
                this.dialogVisible2=true
            },
            getprojectList(){

                let obj = {
                    page: '1',
                    size: '99999'
                };
                let ss= encrypt(JSON.stringify(obj))
                projectList(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        this.listChange=dd.data.records
                    }else {
                        alert(dd.msg);
                    }

                })

            },
            handleChange(val) {
                console.log(val);
            },
            saveAddPLFin(){

                this.btnLoading = true;
                let obj = {
                    name: this.input1,
                };
                let ss= encrypt(JSON.stringify(obj))
                projectAddNew(ss).then(res => {
                    let dd= JSON.parse(decrypt(res))
                    if (dd.code == 200) {
                        let parmars2={
                            tempId:this.$route.query.id,
                            factory:this.$route.query.factory,
                            projId:dd.data,
                            name:this.input,
                            sets:this.inputTs
                        }
                        let parmarsCode2= encrypt(JSON.stringify(parmars2))
                        getConfigPLFin(parmarsCode2).then(ress => {
                            let resCoDE2= JSON.parse(decrypt(ress))
                            console.log(resCoDE2,'resCoDE')
                            if (resCoDE2.code == 200) {
                                this.btnLoading = false;
                                this.$router.push({name:'productList',query: {type:'0',id:dd.data, name: this.input1}})
                            }else {
                                this.btnLoading = false;
                                alert(resCoDE2.msg);
                            }

                        })
                    }else {
                        alert(dd.msg);
                    }

                })
            },
            savePLFin(){

                this.btnLoading = true;
                let parmars={
                    tempId:this.$route.query.id,
                    factory:this.$route.query.factory,
                    projId:this.xmList,
                    name:this.input,
                    sets:this.inputTs
                }
                let names= ''
                    this.listChange.find(item=>{
                    if(item.id==this.xmList){
                        names=item.name
                    }
                })
                let parmarsCode= encrypt(JSON.stringify(parmars))
                getConfigPLFin(parmarsCode).then(res => {
                    let resCoDE= JSON.parse(decrypt(res))
                    console.log(resCoDE,'resCoDE')
                    if (resCoDE.code == 200) {
                        this.btnLoading = false;
                        this.$router.push({name:'productList',query: {type:'0',id:this.xmList,name:names}})
                    }else {
                        this.btnLoading = false;
                        alert(resCoDE.msg);
                    }

                })
            },
            saveFin(){
                this.btnLoading = true;
                let parmars={
                    stdId:this.$route.query.id,
                    projId:this.$route.query.name,
                    name:this.input
                }
                let parmarsCode= encrypt(JSON.stringify(parmars))
                getConfigFin(parmarsCode).then(res => {
                    let resCoDE= JSON.parse(decrypt(res))
                    console.log(resCoDE,'resCoDE')
                    if (resCoDE.code == 200) {
                        this.btnLoading = false;
                        this.$router.push({name:'productList',query: {type:this.$route.query.type}})
                    }else {
                        this.btnLoading = false;
                        alert(resCoDE.msg);
                    }

                })
            },
            addFin(){
                this.btnLoading = true;
                let parmars=this.formdata
                let parmarsCode= encrypt(JSON.stringify(parmars))
                if(this.isEdit==false){
                    customAdd(parmarsCode).then(res => {
                        let resCoDE= JSON.parse(decrypt(res))
                        console.log(resCoDE,'resCoDE')
                        if (resCoDE.code == 200) {
                            this.btnLoading = false;
                            this.dialogVisible2=false;
                            this.gettempList()
                        }else {
                            this.btnLoading = false;
                            alert(resCoDE.msg);
                        }

                    })
                }else {
                    addFinalTrue(parmarsCode).then(res => {
                        let resCoDE= JSON.parse(decrypt(res))
                        console.log(resCoDE,'resCoDE')
                        if (resCoDE.code == 200) {
                            this.btnLoading = false;
                            this.dialogVisible2=false;
                            this.getConfigDetialList()
                        }else {
                            this.btnLoading = false;
                            alert(resCoDE.msg);
                        }

                    })
                }

            },
            openDia(){
                if(this.isEdit==false){
                    this.dialogVisible=true
                }else {
                    this.$router.go(-1)
                }


            },
            getplTypeList(){
                    //this.tableAllData= this.$store.state.configurationInfoList
                    let cList=JSON.parse(sessionStorage.getItem("configurationInfoList")) ;
                    this.tableAllData=cList.data

                    this.projPrice= cList.params.projPrice
                    this.listPrice= cList.params.listPrice
                    cList.data.map((item,index)=>{
                        this.activeNames.push(index)
                    })
            },
            getConfigList(){

               /* let parmars = `listId=${this.$route.query.id}`*/
                let parmars={
                    stdListId:this.$route.query.id
                }
                let parmarsCode= encrypt(JSON.stringify(parmars))
                getConfigList(parmarsCode).then(res => {
                    let resCoDE= JSON.parse(decrypt(res))

                    if (resCoDE.code == 200) {
                       if(resCoDE.data.data.length>0){

                           this.img=resCoDE.data.layoutImg
                           this.imgList=resCoDE.data.legendList
                           this.tableAllData= resCoDE.data.data
                           this.projPrice= resCoDE.data.params.projPrice
                           this.listPrice= resCoDE.data.params.listPrice
                           resCoDE.data.data.map((item,index)=>{
                               this.activeNames.push(index)
                           })
                              /* if(item.data.saleName !=null){
                                  /!* if(item.belong=='一'){
                                       this.tableData1.push(item)
                                   }else if(item.belong=='二'){
                                       this.tableData2.push(item)
                                   }else if(item.belong=='三'){
                                       this.tableData3.push(item)
                                   }else if(item.belong=='四'){
                                       this.tableData4.push(item)
                                   }*!/
                               }*/
                         /*  this.activeNames.push(index)
                           this.tableAllData=resCoDE.data*/
                       }
                    }else {
                        alert(resCoDE.msg);
                    }

                })
            },
            getConfigDetialList(){
               /* let parmars = `listId=${this.$route.query.id}`*/
                let parmars={
                    id:this.$route.query.id
                }
                let parmarsCode= encrypt(JSON.stringify(parmars))
                getConfigFinDetail(parmarsCode).then(res => {
                    let resCoDE= JSON.parse(decrypt(res))

                    if (resCoDE.code == 200) {
                       if(resCoDE.data.data.length>0){

                           this.type=resCoDE.data.type
                           this.img=resCoDE.data.layoutImg
                           this.imgList=resCoDE.data.legendList
                           /*this.tableAllData= resCoDE.data*/
                           this.tableAllData= resCoDE.data.data.map(obj=>{
                                obj.brand=resCoDE.data.brand
                                return obj
                           })
                         this.describe= resCoDE.data.describe
                           /*if(this.tableAllData.length>0){
                               this.tableAllData.map((item,index)=>{
                                   if(item.data.length>0){
                                       item.data.map((item1,index1)=>{
                                           item1.show=false
                                       })

                                   }
                               })
                           }*/
                           console.log(this.tableAllData)
                           this.projPrice= resCoDE.data.params.projPrice
                           this.listPrice= resCoDE.data.params.listPrice
                           resCoDE.data.data.map((item,index)=>{
                               this.activeNames.push(index)
                           })
                              /* if(item.data.saleName !=null){
                                  /!* if(item.belong=='一'){
                                       this.tableData1.push(item)
                                   }else if(item.belong=='二'){
                                       this.tableData2.push(item)
                                   }else if(item.belong=='三'){
                                       this.tableData3.push(item)
                                   }else if(item.belong=='四'){
                                       this.tableData4.push(item)
                                   }*!/
                               }*/
                         /*  this.activeNames.push(index)
                           this.tableAllData=resCoDE.data*/
                       }
                    }else {
                        alert(resCoDE.msg);
                    }

                })
            },
        },
        /*watch:{
            $route(){
                if(this.$route.query.detial=='true'){
                    this.getConfigDetialList()
                    this.isEdit=true
                }else {
                    this.gettempList()
                    this.isEdit=false
                }
                this.getprojectList()
            }
        },*/
        mounted() {

           /* if(this.$route.query.type==null || this.$route.query.name==null){

                if(this.$route.query.plType!=null){
                    this.getplTypeList()
                    this.getprojectList()
                }else {
                    this.getConfigDetialList()
                }
            }
            else {
                this.getConfigList()
            }*/
            debugger
            if(this.$route.query.toP || this.$route.query.type=='3' || this.$route.query.type=='9'){
                this.redTitle=false
            }else {
                this.redTitle=true
            }
            if(this.$route.query.par || this.$route.query.type=='3'){
                this.priceShow=false
            }else {
                this.priceShow=true
            }
            if(this.$route.query.detial=='1'){
                this.getConfigDetialList()
                this.isEdit=true
            }else {
                this.gettempList()
                this.isEdit=false
            }
            this.getprojectList()


           //this.getplTypeList()
        },
    }
</script>

<style lang="less" scoped>
    .imgLstyle{
        width: 12%;

        background: #ececec;
        float: left;
        margin-right: 1%;
        margin-bottom: 20px;

        .imgLstyleT{
            min-height: 120px;
            width: 100%;
            display: flex;
            align-items:center;
            img{
                vertical-align: middle;
                text-align: center;
                margin: 0px auto;
                height: fit-content;
                width: 100%;
            }
        }
        .imgLstyleD{
            width: 100%;
            text-align: center;
            background: #7d7f82;
            color: white;
            height: 23px;
        }
    }
    /deep/ .el-collapse-item__content{
        text-align: center;
    }
   /deep/ .el-breadcrumb__inner a{
        color: #303133 !important;
    }
.cg_main{
    background: white;
    height: 100%;
    min-height: 529px;
    display: flow-root;
    .cg_main_son{
        width: 1400px;
        margin: 0px auto;
        /deep/.el-collapse-item__header{
            height: 60px;
            background: #EFEFEF;font-size: 16px;font-weight: bold;
            color: #111111;
            padding-left: 30px;
        }
    }
}
</style>
